function validateTimeFormat(time) {
  const regexForSeconds = /^[0-9]?[0-9]$/;
  const regexForMinutesSeconds = /^[0-5]?[0-9]:[0-5][0-9]$/;
  const regexForHoursMinutesSeconds = /^[0-9]?[0-9]:[0-5]?[0-9]:[0-5][0-9]$/;

  return (
    time.match(regexForSeconds) != null ||
    time.match(regexForMinutesSeconds) != null ||
    time.match(regexForHoursMinutesSeconds) != null
  );
}

function hmsToSecondsOnly(n) {
  if (!n) n = 0;
  let p = ("" + n).split(":"),
    s = 0,
    m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s;
}

function secondsToHHMMSS(seconds) {
  if (seconds > 3600)
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  else return new Date(seconds * 1000).toISOString().substr(14, 5);
}

export { validateTimeFormat, hmsToSecondsOnly, secondsToHHMMSS };
