import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../styles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { isPlaybackRateSupported } from "../common/domainLogic";
import { trackEvent } from "../common/analytics";
import Replay5Icon from "@mui/icons-material/Replay5";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import CancelIcon from "@mui/icons-material/Cancel";
import { secondsToHHMMSS } from "../common/time";

const RestartButton = (props) => {
  return (
    <IconButton
      aria-label="delete"
      size="large"
      sx={{
        paddingLeft: "9px",
        paddingRight: "9px",
        paddingVertical: "8px",
        left: "-9px",
        fontSize: 36,
      }}
      onClick={(e) => {
        props.refreshPlayer(props.uri, props.start);
        trackEvent("Controller", "Restart", "Button");
      }}
    >
      <FirstPageIcon fontSize="inherit" />
    </IconButton>
  );
};

const LoopOff = (props) => {
  return (
    <IconButton
      aria-label="delete"
      size="small"
      color="error"
      onClick={(e) => {
        props.setUseBookmarks(false);
        props.setActiveBookmark(null);
        props.setStart("0:00");
        trackEvent("Controller", "Clear", "Button");
      }}
    >
      <CancelIcon fontSize="inheritt" />
    </IconButton>
  );
};

const SpeedControl = (props) => {
  return (
    <Box
      sx={{
        width: 100,
        display: "inline-block",
        marginRight: 1,
        paddingBottom: ".1rem",
        border: 1,
        borderColor: "#d3d3d3",
        borderRadius: 1,
      }}
    >
      <Typography
        id="input-slider"
        sx={{ fontSize: ".8rem", textAlign: "center" }}
      >
        Speed
      </Typography>
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
              if (props.playbackRate > 0.05) {
                const newRate = (parseFloat(props.playbackRate) - 0.05).toFixed(
                  2
                );
                trackEvent("Controller", "SpeedDown", newRate);
                props.setPlaybackRate(newRate);
              }
            }}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Typography
            id="input-slider"
            sx={{ userSelect: "none", fontSize: ".9rem", textAlign: "center" }}
          >
            {props.playbackRate}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
              if (props.playbackRate < 2.0) {
                const newRate = (parseFloat(props.playbackRate) + 0.05).toFixed(
                  2
                );
                trackEvent("Controller", "SpeedUp", newRate);
                props.setPlaybackRate(newRate);
              }
            }}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const LoopDelay = (props) => {
  return (
    <FormControl sx={{ marginRight: 1, width: 65 }}>
      <InputLabel>Delay</InputLabel>
      <Select
        labelId="Delay"
        id="delay"
        value={props.loopDelay}
        onChange={(e) => {
          props.setLoopDelay(e.target.value);
          trackEvent("Controller", "LoopDelay", e.target.value);
        }}
        autoWidth
        sx={{ height: 45 }}
        label="Delay"
      >
        <MenuItem value={0}>0s</MenuItem>
        <MenuItem value={1}>1s</MenuItem>
        <MenuItem value={2}>2s</MenuItem>
        <MenuItem value={3}>3s</MenuItem>
        <MenuItem value={4}>4s</MenuItem>
        <MenuItem value={5}>5s</MenuItem>
        <MenuItem value={6}>6s</MenuItem>
        <MenuItem value={7}>7s</MenuItem>
        <MenuItem value={8}>8s</MenuItem>
        <MenuItem value={9}>9s</MenuItem>
      </Select>
    </FormControl>
  );
};

function Controller(props) {
  // Hacky way to begin playing on mobile
  useEffect(() => {
    if (!props.hasPlayerPlayed) {
      // This will fire after the playing state is set to false, after which
      // we will try setting the palying state to true
      props.setPlaying(true);
    }
  }, [props.playing]);

  return (
    <Box sx={{ marginBottom: "1rem" }}>
      <Grid container alignItems="flex-end">
        <Grid item>
          <RestartButton
            refreshPlayer={props.refreshPlayer}
            uri={props.uri}
            start={props.start}
          />{" "}
        </Grid>
        <Grid item>
          <IconButton
            aria-label="delete"
            size="large"
            sx={{
              paddingLeft: "9px",
              paddingRight: "9px",
              paddingVertical: "8px",
              left: "-9px",
              fontSize: 36,
            }}
            onClick={(e) => {
              props.rewindSeconds(5);
              trackEvent("Controller", "Rewind5", "Button");
            }}
          >
            <Replay5Icon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="delete"
            size="large"
            sx={{
              paddingLeft: "9px",
              paddingRight: "9px",
              paddingVertical: "8px",
              left: "-9px",
              fontSize: 36,
            }}
            onClick={(e) => {
              props.setPlaying(!props.playing);
            }}
          >
            {props.playing && props.hasPlayerPlayed ? (
              <PauseIcon fontSize="inherit" />
            ) : (
              <PlayArrowIcon fontSize="inherit" />
            )}
          </IconButton>
        </Grid>
        {isPlaybackRateSupported(props.uri) ? (
          <Grid item>
            <SpeedControl
              playbackRate={props.playbackRate}
              setPlaybackRate={props.setPlaybackRate}
            />
          </Grid>
        ) : null}
        <Grid item>
          <LoopDelay
            loopDelay={props.loopDelay}
            setLoopDelay={props.setLoopDelay}
          />
        </Grid>
        <Grid item>
          <Box sx={{ marginTop: 0.5 }}>
            {props.activeBookmark ? (
              <LoopOff
                useBookmarks={props.useBookmarks}
                setUseBookmarks={props.setUseBookmarks}
                setActiveBookmark={props.setActiveBookmark}
                setStart={props.setStart}
              />
            ) : null}

            <Typography
              sx={{
                fontSize: props.vidWidth > 400 ? "1rem" : ".8rem",
                fontWeight: "bold",
                display: "inline-block",
                color: props.activeBookmark ? "default" : "#aaaaaa",
              }}
            >
              {props.activeBookmark
                ? "Loop #" + (props.numLoops + 1) + ":"
                : "No Loop Loaded:"}
            </Typography>

            <Typography
              sx={{
                mx: 1,
                display: "inline-block",
                fontSize: props.vidWidth > 400 ? "1rem" : ".8rem",
              }}
            >
              {props.start + " - "}{" "}
              {props.activeBookmark && props.end
                ? props.end
                : secondsToHHMMSS(props.mediaDuration)}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                display: "inline-block",
                fontSize: props.vidWidth > 400 ? "1rem" : ".8rem",
              }}
            >
              ({props.playedTime})
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: props.vidWidth > 400 ? "1rem" : ".8rem",
                marginLeft: ".5rem",
                color: "red",
              }}
            >
              {props.activeBookmark ? props.activeBookmark : ""}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Controller;
