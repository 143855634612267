import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "./components/Copyright";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Link from "@mui/material/Link";
import { useParams } from "react-router-dom";
import { DiscussionEmbed, CommentCount } from "disqus-react";
import { Helmet } from "react-helmet";
import { trackPageView } from "./common/analytics";
import Pagination from "@mui/material/Pagination";

const disqusShortname = "loopandlearn-app-1";

const query = `
query blogPostCollection($limit: Int, $skip: Int, $slug: String, $tag: [String]) {
    blogPostCollection(order: sys_firstPublishedAt_DESC, skip: $skip, where: {slug: $slug, tags_contains_all: $tag}, limit: $limit) {
      total
      items {
        title
        tags
        slug
        body {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
                __typename
                ... on BlogPost {
                  title
                }
                ... on VideoEmbed {
                  embedUrl
                }
              }
              block {
                sys {
                  id
                }
                ... on VideoEmbed {
                  embedUrl
                }
              }
            }
            assets {
              block {
                sys {
                  id
                }
                url
                title
                width
                height
                description
              }
            }
          }
        }
      }
    }
  }
  `;

function renderOptions(links, containerWidth) {
  // create an asset map
  const assetMap = new Map();
  // loop through the assets and add them to the map
  for (const asset of links.assets.block) {
    assetMap.set(asset.sys.id, asset);
  }

  // create an entry map
  const entryMap = new Map();
  // loop through the block linked entries and add them to the map
  for (const entry of links.entries.block) {
    entryMap.set(entry.sys.id, entry);
  }

  // loop through the inline linked entries and add them to the map
  for (const entry of links.entries.inline) {
    entryMap.set(entry.sys.id, entry);
  }

  return {
    // other options...

    renderNode: {
      // other options...
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        // find the entry in the entryMap by ID
        const entry = entryMap.get(node.data.target.sys.id);

        // render the entries as needed
        if (entry.__typename === "BlogPost") {
          return <a href={`/blog/${entry.slug}`}>{entry.title}</a>;
        }
        if (entry.__typename === "VideoEmbed") {
          const width = containerWidth < 650 ? containerWidth : "400";
          const height =
            containerWidth < 650 ? (containerWidth * 9) / 16 : (width * 9) / 16;
          return (
            <Box
              as="span"
              sx={{
                float: "left",
                marginBottom: "1rem",
                marginRight: "2rem",
              }}
            >
              <iframe
                src={entry.embedUrl}
                height={height}
                width={width}
                frameBorder="0"
                scrolling="no"
                allowFullScreen
              />
            </Box>
          );
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        // find the entry in the entryMap by ID
        const entry = entryMap.get(node.data.target.sys.id);

        // render the entries as needed by looking at the __typename
        // referenced in the GraphQL query
        if (entry.__typename === "CodeBlock") {
          return (
            <pre>
              <code>{entry.code}</code>
            </pre>
          );
        }

        if (entry.__typename === "VideoEmbed") {
          return (
            <iframe
              src={entry.embedUrl}
              height="100%"
              width="100%"
              frameBorder="0"
              scrolling="no"
              title={entry.title}
              allowFullScreen={true}
            />
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        // find the asset in the assetMap by ID
        const asset = assetMap.get(node.data.target.sys.id);
        const width =
          containerWidth < 650 ? { width: "100%" } : { width: "400" };
        const styles = {
          ...{
            float: "left",
            marginBottom: "2rem",
            marginRight: "2rem",
          },
          ...width,
        };

        // render the asset accordingly
        return <img src={asset.url} alt="My image alt text" style={styles} />;
      },
    },
  };
}

const DisqusElement = (props) => {
  return props.isSinglePage ? (
    <Box mt={"8rem"}>
      <DiscussionEmbed
        shortname={disqusShortname}
        config={{
          url: "https://loopandlearn.app/entry/" + props.entry.slug,
          identifier: props.entry.slug,
          title: props.entry.title,
        }}
      />
    </Box>
  ) : (
    <Box sx={{ mt: 4, textAlign: "right", mr: 4 }}>
      <Link href={"/entry/" + props.entry.slug}>
        {" "}
        <CommentCount
          shortname={disqusShortname}
          config={{
            url: "https://loopandlearn.app/entry/" + props.entry.slug,
            identifier: props.entry.slug,
            title: props.entry.title,
          }}
        >
          {/* Placeholder Text */}
          Comments
        </CommentCount>
      </Link>
    </Box>
  );
};

const PaginationByTens = (props) => {
  const numPages = Math.ceil(props.totalPosts / props.limit);
  if (numPages > 1)
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Pagination
          count={numPages}
          page={props.currentPage}
          onChange={(e, v) => {
            if (props.currentPage != v) {
              window.location = v == 1 ? "/blog" : "/blog/page/" + v;
            }
          }}
        />
      </Box>
    );
  else return null;
};

export default function Blog(props) {
  //  const entries = getEntries();

  const [page, setPage] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const [totalPosts, setTotalPosts] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 4;

  // Skip the initial render https://stackoverflow.com/a/61072832/2012659
  // This is unnecessary when useEffect is used with [], but otherwise
  // it is called twice.
  const isInitialRender = useRef(true);

  const routerParams = useParams();

  const isSinglePage = "*" in routerParams && props.type == "entry";

  useEffect(() => {
    if (routerParams && "*" in routerParams && props.type == "tag") {
      const title = "Loops about " + routerParams["*"];
      setPageTitle(title);
      if (isInitialRender.current) trackPageView(title);
    } else if (routerParams && "*" in routerParams && props.type == "page") {
      const page = parseInt(routerParams["*"]);
      const title = "The Infinite Loop";
      setCurrentPage(page);
      setPageTitle(title);
      if (isInitialRender.current) trackPageView(title);
    } else if (routerParams && "*" in routerParams && props.type == "entry") {
      const title = routerParams["*"]
        .replaceAll("-", " ")
        .split(" ")
        .map(capitalize)
        .join(" ");
      setPageTitle(title);
      if (isInitialRender.current) trackPageView(title);
    } else {
      const title = "The Infinite Loop";
      setPageTitle(title);
      if (isInitialRender.current) trackPageView(title);
    }
    isInitialRender.current = false;
  }, [routerParams]);

  // GraphQL variables: https://www.contentful.com/blog/2021/10/22/how-to-use-graphql-variables/
  useEffect(() => {
    let variables = { limit: limit, skip: (currentPage - 1) * limit };
    if (routerParams && "*" in routerParams)
      if (props.type == "tag") variables.tag = routerParams["*"];
      else if (props.type == "entry") variables.slug = routerParams["*"];

    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/901u2fmlln4b/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer yBPrZ3midgXECttXOe8mrHUpIcYOz7pktEotZuc32Uw",
        },
        // send the GraphQL query
        body: JSON.stringify({ query, variables }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data\
        setTotalPosts(data.blogPostCollection.total);
        setPage(data.blogPostCollection);
      });
  }, [routerParams, currentPage]);

  const BlogBody = () => {
    if (!page) return <Typography>Loading ...</Typography>;
    else
      return page.items.map((entry, i) => (
        <Box key={i} mb={5} mt={5}>
          {isSinglePage ? (
            <Helmet>
              <title>{entry.title}</title>
            </Helmet>
          ) : null}
          <Typography
            variant="h2"
            fontSize="2.0rem !important"
            component="div"
            gutterBottom
          >
            <Link href={"/entry/" + entry.slug} underline="none">
              {entry.title}
            </Link>
          </Typography>
          <Typography variant="subtitle2" component="div" mb={4} gutterBottom>
            <Typography component="span" mr={2}>
              Tags:
            </Typography>
            {entry.tags.map((tag) => (
              <Typography component="span" mr={2} key={tag}>
                <Link href={"/tag/" + tag} underline="none">
                  {" "}
                  {tag}{" "}
                </Link>
              </Typography>
            ))}
          </Typography>
          <Box sx={{ minHeight: "19rem" }}>
            {documentToReactComponents(
              entry.body.json,
              renderOptions(entry.body.links, props.containerWidth)
            )}
          </Box>
          {/* <DisqusElement isSinglePage={isSinglePage} entry={entry} /> */}
        </Box>
      ));
  };

  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="A curated collection of our favorite microlearning loops! Loopandlearn.app allows musicians to create, save, bookmark, and share multiple sections of Youtube and other streaming video/audio sources to study and play along with."
        />
      </Helmet>
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography variant="h1" component="div" gutterBottom>
          The Infinite Loop
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          ... microlearning music with bite-sized loops.
        </Typography>
      </Box>
      <PaginationByTens
        totalPosts={totalPosts}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limit={limit}
      />
      <BlogBody />
      <PaginationByTens
        totalPosts={totalPosts}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limit={limit}
      />
      <Copyright />
    </Box>
  );
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
