import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { InputBase } from "@mui/material";
import "./styles.css";
import Button from "@mui/material/Button";
import ReactPlayer from "react-player";
import Bookmarks from "./components/Bookmarks";
import Copyright from "./components/Copyright";
import LoopEditor from "./components/LoopEditor";
import {
  validateTimeFormat,
  hmsToSecondsOnly,
  secondsToHHMMSS,
} from "./common/time";
import Controller from "./components/Controller";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { isPlaybackRateSupported } from "./common/domainLogic";
import { trackEvent, trackPageView } from "./common/analytics";
import { Helmet } from "react-helmet";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";

const pageTitle = "Loop YouTube and Audio!";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSYH_-t8ik2aGX2VWbDyKS18QAb1S_dg8",
  authDomain: "practicer.firebaseapp.com",
  projectId: "youtubepracticer",
  storageBucket: "youtubepracticer.appspot.com",
  messagingSenderId: "895176085553",
  appId: "1:895176085553:web:0d0ef006eca50206ee0510",
  measurementId: "G-P9SW5FKLV5",
};

const getShortlinkedQuery = async (shortlink) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore();

  const docRef = doc(db, "shortlinks", shortlink);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const query = docSnap.data();
    return query;
  } else {
    return null;
  }
};

export default function LoopAndLearn(props) {
  const reactPlayerRef = useRef();

  const [numLoops, setNumLoops] = useState(0);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleHelperText, setTitleHelperText] = useState("");

  const [uri, setUri] = useState("");

  const [start, setStart] = useState("0:00");
  const [end, setEnd] = useState("");

  const [bookmarks, setBookmarks] = useState([]);
  const [useBookmarks, setUseBookmarks] = useState(false);

  const [activeBookmark, setActiveBookmark] = useState("");

  const [time, setTime] = useState(0);

  const [bookmarkToEdit, setBookmarkToEdit] = useState(null);

  const [mediaDuration, setMediaDuration] = useState(-1.0);

  const [playing, setPlaying] = useState(true);
  // This is necessary because on mobile, player will not play except if
  // user clicks, so the state of this button and the player might be
  // out of sync.
  const [hasPlayerPlayed, setHasPlayerPlayed] = useState(false);

  const videoBoxRef = useRef(null);
  const [loopDelay, setLoopDelay] = useState(0);

  const [playbackRate, setPlaybackRate] = useState("1.00");

  const [expandLoopEditor, setExpandLoopEditor] = useState(true);

  const [localFile, setLocalFile] = useState(null);
  const [localFileName, setLocalFileName] = useState(null);

  const routerParams = useParams();

  const [vidHeight, setVidHeight] = useState(48);

  const [bookmarksExpanded, setBookmarksExpanded] = useState(true);
  const [playedTime, setPlayedTime] = useState("0:00");

  const [playerFirstReady, setPlayerFirstReady] = useState(false);
  const [activateFirstBookmarkOnLoad, setActivateFirstBookmarkOnLoad] =
    useState(false);

  useEffect(() => {
    if (routerParams && "*" in routerParams) {
      const shortlink = routerParams["*"];
      if (shortlink) {
        (async () => {
          const queryParams = await getShortlinkedQuery(shortlink);
          if (queryParams && "query" in queryParams && queryParams.query) {
            console.log("shortlink: " + queryParams.query);
            loadFromQueryParams(queryParams.query);
          }
        })();
      }
    } else {
      loadFromUriParams();
    }
    trackPageView(pageTitle);
  }, []);

  /**
   * Automatically load the first loop when loading from a premade query.
   *
   * This should only be done exactly once (on load) so we use a state
   * variable to track when this is ready to be called (when the player loads)
   * and turn it off immediately afterwards.
   */
  useEffect(() => {
    if (
      playerFirstReady &&
      bookmarks.length > 0 &&
      activateFirstBookmarkOnLoad
    ) {
      //Load first bookmark
      loadBookmark(bookmarks[0]);
      refreshPlayer(uri, bookmarks[0].start);
      setActivateFirstBookmarkOnLoad(false);
    }
  }, [playerFirstReady, bookmarks, activateFirstBookmarkOnLoad]);

  useEffect(() => {
    let newHeight = (props.containerWidth * 9) / 16;
    // Try to detect whether this file is an audio file
    let isAudio = false;
    const audioSuffixes = [
      ".mp3",
      ".ogg",
      ".m4a",
      ".wav",
      ".aac",
      ".ac3",
      ".aiff",
      ".flac",
      ".wma",
    ];
    audioSuffixes.forEach((suffix) => {
      if (uri && uri.endsWith(suffix)) isAudio = true;
      else if (localFileName && localFileName.endsWith(suffix)) isAudio = true;
    });
    if (isAudio) newHeight = 48;
    setVidHeight(newHeight);
  });

  const getTime = (e) => {
    if (!reactPlayerRef.current) return "0:00";
    return reactPlayerRef.current.getCurrentTime();
  };

  const onChangeTitle = (e) => {
    let error = false;
    if (e.target.value.length > 50) {
      setTitleError(true);
      setTitleHelperText("Title cannot exceed 50 characters");
    } else {
      setTitleError(false);
      setTitleHelperText("");
    }
    if (error) return;

    setTitle(e.target.value);
    refreshQueryString(e.target.value, uri, bookmarks);
  };

  function refreshQueryString(newTitle, newUri, newBookmarks) {
    let queryString = "";
    if (newUri && !localFile) queryString += "&v=" + encodeURIComponent(newUri);
    if (newTitle) {
      queryString += "&t=" + encodeURIComponent(newTitle);
    }
    newBookmarks.forEach((mark) => {
      queryString +=
        "&b=" +
        encodeURIComponent(mark.name) +
        "," +
        mark.start +
        "," +
        mark.end;
    });
    if (queryString) {
      queryString = "?" + queryString.substr(1);
    }
    window.history.replaceState({}, "", window.location.origin + queryString);
  }

  const updateBookmarks = (newBookmarks) => {
    setBookmarks(newBookmarks);
    refreshQueryString(title, uri, newBookmarks);
  };

  const onDelete = (event, bookmark) => {
    event.stopPropagation();
    const newBookmarks = bookmarks.filter(
      (mark) => mark.name && mark.name != bookmark.name
    );
    setBookmarks(newBookmarks);
    refreshQueryString(title, uri, newBookmarks);
  };

  const onReorder = (event, reorderedBookmarks) => {
    setBookmarks(reorderedBookmarks);
    refreshQueryString(title, uri, reorderedBookmarks);
  };

  const onEdit = (event, bookmark) => {
    event.stopPropagation();
    setExpandLoopEditor(true);
    setBookmarkToEdit(bookmark);
  };

  const clearAllBookmarks = (event) => {
    setBookmarks([]);
    setExpandLoopEditor(true);
    refreshQueryString(title, uri, []);
  };

  const onLoad = (event, bookmark) => {
    event.stopPropagation();
    loadBookmark(bookmark);
    refreshPlayerWithDelay(uri, bookmark.start);
  };

  const loadBookmark = (bookmark) => {
    setUseBookmarks(true);
    setStart(bookmark.start);
    setEnd(bookmark.end);
    if (activeBookmark != bookmark.name) {
      setNumLoops(0);
      setActiveBookmark(bookmark.name);
    }
  };

  const liveUpdate = (time) => {
    refreshPlayer(uri, time);
    // Bookmarks must be turned off when we update otherwise it will
    // overwite
    setUseBookmarks(false);
    setActiveBookmark(null);
    setNumLoops(0);

    // Reset start to 0 so that the restart button acts sanely
    setStart("0:00");
  };

  function delay() {
    const delayValue = !isNaN(loopDelay) ? loopDelay : 0;
    if (loopDelay > 0) {
      setPlaying(false);
      setTimeout(() => {
        setPlaying(true);
      }, delayValue * 1000);
    }
  }

  function refreshPlayerWithDelay(uri, time) {
    delay();
    refreshPlayer(uri, time);
  }

  function rewindSeconds(seconds) {
    if (reactPlayerRef.current) {
      const targetTime = Math.max(0, getTime() - seconds);
      reactPlayerRef.current.seekTo(targetTime);
    }
  }

  function refreshPlayer(uri, time) {
    if (reactPlayerRef.current && validateTimeFormat(time)) {
      reactPlayerRef.current.seekTo(hmsToSecondsOnly(time));
    }
  }

  const handleVideoProgress = (state) => {
    setPlayedTime(secondsToHHMMSS(state.playedSeconds));
    if (
      state.playedSeconds > hmsToSecondsOnly(end) &&
      (hmsToSecondsOnly(end) > hmsToSecondsOnly(start) ||
        ((end == "" || hmsToSecondsOnly(end) == 0) &&
          reactPlayerRef.current.getCurrentTime() >
            reactPlayerRef.current.getDuration() - 1)) &&
      useBookmarks
    ) {
      reactPlayerRef.current.seekTo(hmsToSecondsOnly(start));
      const delayValue = !isNaN(loopDelay) ? loopDelay : 0;
      if (loopDelay > 0) {
        setPlaying(false);
        setTimeout(() => {
          setPlaying(true);
        }, delayValue * 1000);
      }
      setNumLoops(numLoops + 1);
    }
  };

  function loadFromUriParams() {
    const queryParams = window.location.href.split("?")[1];
    loadFromQueryParams(queryParams);
  }

  function loadFromQueryParams(queryParams) {
    if (queryParams) {
      try {
        const queryJson = queryParams.split("&").map((x) => x.split("="));
        let queryBookmarks = [];
        let payload = {};
        let newUri = "";
        queryJson.forEach(([k, v]) => {
          if (k == "v") {
            newUri = decodeURIComponent(v);
          } else if (k == "t" && title != v) {
            setTitle(decodeURIComponent(v));
          } else if (k == "b") {
            const [name, start, end] = v.split(",");
            if (
              name &&
              start &&
              !queryBookmarks.map((entry) => entry.name).includes(name)
            ) {
              queryBookmarks.push({
                name: decodeURIComponent(name),
                start: start,
                end: end,
              });
            }
          }
        });
        if (newUri && newUri != uri) {
          setUri(newUri);
        }
        if (JSON.stringify(queryBookmarks) != JSON.stringify(bookmarks)) {
          setBookmarks(queryBookmarks);
          setExpandLoopEditor(false);
          setActivateFirstBookmarkOnLoad(true);
        }
        payload["bookmarks"] = queryBookmarks;
      } catch (e) {
        console.log("Invalid query params: " + queryParams + "\n" + e.message);
      }
    }
  }

  const playerProps = {
    ref: (e) => (reactPlayerRef.current = e),
    url: uri,
    loop: true,
    controls: true,
    progressInterval: 500,
    width: props.containerWidth,
    height: vidHeight,
    onPause: (e) => {
      setPlaying(false);
    },
    onSeek: (e) => {
      if (e.playedSeconds) setPlayedTime(secondsToHHMMSS(e.playedSeconds));
    },
    onPlay: (e) => {
      setPlaying(true);
      setHasPlayerPlayed(true);
    },
    onProgress: handleVideoProgress,
    onDuration: (e) => setMediaDuration(e),
    onReady: (e) => {
      setPlayerFirstReady(true);
      trackEvent("Source", uri.startsWith("blob") ? "Local" : "Streaming", uri);
    },
    playing: playing,
  };
  if (isPlaybackRateSupported(uri)) {
    playerProps.playbackRate = parseFloat(playbackRate);
  }

  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Learn music FAST and easy! Create, save, bookmark, and share multiple sections of Youtube and streaming video/audio to study and play along with. Loop it until you learn it!"
        />
      </Helmet>
      <InputBase
        sx={{ fontSize: "1.4rem", marginBottom: 1.5 }}
        value={title}
        placeholder="Untitled"
        align="center"
        onChange={onChangeTitle}
        fullWidth
      ></InputBase>
      <TextField
        ref={videoBoxRef}
        value={localFileName ? localFileName : uri}
        id="youtube-uri"
        label="Audio/Video URL (YouTube / Vimeo / Facebook / Twitch / DailyMotion / Soundcloud / mp3, etc.)"
        variant="standard"
        fullWidth
        onChange={(e) => {
          setLocalFile(null);
          setLocalFileName("");
          setUri(e.target.value);
          refreshQueryString(title, e.target.value, bookmarks);
        }}
      />
      <input
        style={{ display: "none" }}
        type="file"
        id="file-input"
        onChange={(e) => {
          if (e.target.files) {
            const fileObject = URL.createObjectURL(e.target.files[0]);
            setLocalFile(fileObject);
            setLocalFileName(e.target.files[0].name);
            setUri(fileObject);
            refreshQueryString(title, "", bookmarks);

            // Its necessary to set the value back to null to fire onChange
            // again if the user re-selects the same file
            e.target.value = null;
          }
        }}
      />
      <button
        onClick={(e) => {
          document.getElementById("file-input").click();
        }}
      >
        ...or load a local file
      </button>
      <Box sx={{ mt: 2, mb: 1, backgroundColor: "#eeeeee" }}>
        <ReactPlayer {...playerProps} />
      </Box>
      <Controller
        refreshPlayer={refreshPlayerWithDelay}
        rewindSeconds={rewindSeconds}
        useBookmarks={useBookmarks}
        setUseBookmarks={setUseBookmarks}
        activeBookmark={activeBookmark}
        setActiveBookmark={setActiveBookmark}
        vidWidth={props.containerWidth}
        start={start}
        end={end}
        uri={uri}
        numLoops={numLoops}
        loopDelay={loopDelay}
        setLoopDelay={setLoopDelay}
        setStart={setStart}
        playbackRate={playbackRate}
        hasPlayerPlayed={hasPlayerPlayed}
        playing={playing}
        playedTime={playedTime}
        setPlaying={setPlaying}
        setPlaybackRate={setPlaybackRate}
        mediaDuration={mediaDuration}
      />
      <LoopEditor
        bookmarks={bookmarks}
        updateBookmarks={updateBookmarks}
        bookmarkToEdit={bookmarkToEdit}
        setBookmarkToEdit={setBookmarkToEdit}
        setLiveUpdate={liveUpdate}
        mediaDuration={mediaDuration}
        getCurrentTimeCallback={getTime}
        expanded={expandLoopEditor}
        setExpanded={setExpandLoopEditor}
      />
      {bookmarks.length > 1 ? (
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          {bookmarksExpanded ? (
            <UnfoldLessIcon
              fontSize="small"
              onClick={(e) => setBookmarksExpanded(false)}
            />
          ) : (
            <UnfoldMoreIcon
              fontSize="small"
              onClick={(e) => setBookmarksExpanded(true)}
            />
          )}
        </Box>
      ) : null}
      <Box
        sx={{
          height:
            bookmarksExpanded || bookmarks.length <= 1
              ? undefined
              : Math.min(17, bookmarks.length * 3) + "rem",
          overflowY: "scroll",
        }}
      >
        <Box>
          <Bookmarks
            bookmarks={bookmarks}
            highlighted={activeBookmark}
            onClickCallback={onLoad}
            onDeleteCallback={onDelete}
            onEditCallback={onEdit}
            onReorderCallback={onReorder}
            screenWidth={props.containerWidth}
          />
          <Box
            height={
              bookmarksExpanded || bookmarks.length <= 1
                ? 0
                : Math.min(13, bookmarks.length * 3) + "rem"
            }
          />
        </Box>
      </Box>
      <Button
        sx={{ marginTop: 2 }}
        variant="outlined"
        color="error"
        onClick={(e) => {
          if (
            confirm(
              "Are you really sure you want to clear all your bookmarks?\n\nCopy and store the URL first if you want to save the current state!"
            )
          ) {
            clearAllBookmarks();
            trackEvent("Clear", "ClearAllBookmarks", bookmarks.length);
          }
        }}
      >
        Clear All Loops
      </Button>
      <Copyright />
    </Box>
  );
}
