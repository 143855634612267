import ReactGA from "react-ga4";

const trackPageView = (title) => {
  ReactGA.send({
    hitType: "pageview",
    page: "location.pathname + location.search",
    title: title || document.title,
  });
  //   console.log(
  //     "GA PageView: " +
  //       location.pathname +
  //       location.search +
  //       ", " +
  //       (title || document.title)
  //   );
};

const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
  //   console.log("GA Event: ", category, ":", action, ":", label);
};

export { trackEvent, trackPageView };
