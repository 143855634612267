const isPlaybackRateSupported = (uri) => {
  return (
    document.getElementsByTagName("video").length +
    document.getElementsByTagName("audio").length +
    (document.getElementsByTagName("iframe").length &&
      +getDomain(uri).includes("youtu"))
  );
};

function getDomain(url) {
  if (!url) return "";
  url = url.replace(/((.*)https?:\/\/)?(www.)?/i, "");

  if (url.indexOf("/") !== -1) {
    return url.split("/")[0];
  }

  return url;
}

export { isPlaybackRateSupported };
