import { useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "./components/Copyright";
import { Helmet } from "react-helmet";
import { trackPageView } from "./common/analytics";

const pageTitle = "How to Loop and Learn";
export default function Instructions(props) {
  useEffect(() => {
    trackPageView(pageTitle);
  }, []);

  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Instructions for looptolearn.app, a free-to-use web application that makes it easy to loop parts of Youtube videos, mp3s, etc. over and over -- so that you can hear and play along on that specific part you need practice on -- until you get it!"
        />
      </Helmet>
      <Typography variant="h1" component="div" gutterBottom>
        How to Loop
      </Typography>
      <Typography variant="h6" component="div" gutterBottom>
        There's lots of cool songs, solos, riffs, and accompaniments out there
        on YouTube for musicians.
      </Typography>
      <Typography variant="h6" component="div" gutterBottom mt={4}>
        <Link to="/">LoopToLearn.app</Link> is a free-to-use web application
        that makes it easy to bookmark and loop specific parts of Youtube
        videos, mp3s, etc. over and over -- so that you can hear and play along
        on that specific portion you need practice on -- until you get it!
      </Typography>
      <Typography variant="h4" mt={6}>
        Examples
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Here is an example loop I made to{" "}
            <a
              href="/?v=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DaJWAwmaUsUQ&t=Basic%20Gospel%20Progressions%20(Worship%20Leader%20Hangout)&b=736m514%20(Db),02:20,02:30&b=736m514%20(D),04:04,04:17&b=736m514%20(E),04:23,04:37&b=736m514%20(F),04:50,05:11&b=Breakdown%20(Db),05:29,06:16&b=736m514%20(C),06:59,07:09&b=Awesome%20Solo,08:29,00:00"
              target="_blank"
            >
              learn some basic piano gospel progressions by looping parts of a
              YouTube video
            </a>{" "}
            from the awesome guys at{" "}
            <a
              href="https://www.youtube.com/channel/UCtcmgokNJGuLVwZ0Tw92Szw"
              target="_blank"
            >
              Worship Leader Hangout
            </a>
            .
          </li>
          <li>
            Here is an example loop I made to help my daughter (an aspiring
            violinist) loop portions of a YouTube video to{" "}
            <a
              href="/?v=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DG2J36wMb-Xo&t=Vivaldi%20Violin%20Concerto%20in%20A%20Minor&b=0-12,00:10,0:43&b=13-20,0:43,1:05&b=21-31,1:04,1:32&b=32-44,1:32,1:56&b=45-56,1:56,2:35&b=58-71,2:35,3:09&b=71-80,3:09,3:35&b=practice%201,2:53,3:10&b=last%20page,2:33,3:35&b=Whole%20Song%201st%20Mvmt,0:08,3:35"
              target="_blank"
            >
              practice the 1st movement of the Vivaldi Violin Concerto in A
              minor with a YouTube piano accompaniment
            </a>{" "}
            by the folks at{" "}
            <a
              href="https://www.youtube.com/channel/UCdQ0xEWv_Hx-fBPDMEnkGOQ"
              target="_blank"
            >
              Piano Accompaniment
            </a>
            .
          </li>
        </ul>
      </Typography>
      <Typography variant="h3" component="div" gutterBottom mt={6}>
        Selecting a video
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        To select a video to make bookmarks in and loop, just paste the URL of a
        YouTube video into the text area. We support lots of different videos -
        Youtube, Vimeo, Facebook, Twitch, DailyMotion, etc. We also support
        SoundCloud for audio.{" "}
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        That's it! Once you paste the URL of a video, it will start playing!
        When you are done creating loops, you can copy the URL in the browser
        bar and and come back to your loop later (or send it to your friends)!
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        Finally, don't forget to give this page a meaningful title that you will
        remember!
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          sx={{ width: "85%" }}
          mt={3}
          component="img"
          src="img/howto_video_selection.png"
          alt="Selecting a video for LoopToLearn.app"
          border={1}
        />
      </Box>
      <Box ml={4}>
        <Typography variant="h5" component="div" gutterBottom mt={4}>
          Using local files
        </Typography>
        <Typography variant="body" component="div" gutterBottom>
          You can also import a local file, such as an mp3, by clicking on the
          box at the bottom of the text area. (However, note that never upload
          files onto our servers, so we cannot save this file -- you will have
          to reselect local files each time you come back to the page.)
        </Typography>
      </Box>
      <Typography variant="h3" component="div" gutterBottom mt={6}>
        Controls
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        You can play the video using the built-in video controls.{" "}
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        We also provide convenient controls to <b>restart the loop</b>, to{" "}
        <b>rewind 5 seconds</b>, to <b>play/pause</b> (since native video
        controls can sometimes be difficult to use), to <b>speed up and slow</b>{" "}
        down the video, or to add a small delay between loops (the program will
        pause for a few seconds so that you can get your instrument ready again,
        for example).
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        When a loop is active, it will display here. If you want to stop
        looping, you can <b>hit the red x button</b> by the loop information to
        deactivate the loop, and then the video will simply play as normal.
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          sx={{ width: "85%" }}
          mt={3}
          component="img"
          src="img/howto_controls.png"
          alt="LoopToLearn.app Controls"
          border={1}
        />
      </Box>
      <Typography variant="h3" component="div" gutterBottom mt={6}>
        Add Loops
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        Its time to add some loops! When you find that cool solo and you know
        where you want your loop to start, hit "Set Start". Then go to where you
        want the loop to end and hit "Set End".
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        If you want the loop to go all the way to the end of the video, you can
        leave End at 0:00.
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        Then give your loop a name, and hit Create!
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          sx={{ width: "85%" }}
          mt={3}
          component="img"
          src="img/howto_add_loops.png"
          alt="LoopToLearn.app Create Loops"
          border={1}
        />
      </Box>
      <Typography variant="body" component="div" gutterBottom mt={3}>
        Alternatively, if you don't like scrubbing through the video to find
        your start/end times, or if you already know where you want the loop to
        be, you can simply type the start and end times in the boxes.
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        If "Live Update" is on, then directly entering times will cause the
        video to jump directly to the timestamp you just entered, so you can
        preview whether you got the time right.
      </Typography>
      <Typography variant="h3" component="div" gutterBottom mt={6}>
        Loading your Loops
      </Typography>
      <Typography variant="body" component="div" gutterBottom>
        Once you've created your loops, just click "Load" to begin your loop!
        You can also expand the box in order to edit or delete your loop.
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          sx={{ width: "85%" }}
          mt={3}
          component="img"
          src="img/howto_use_loops.png"
          alt="LoopToLearn.app Load Loops"
          border={1}
        />
      </Box>
      <Typography variant="body" component="div" gutterBottom mt={6}>
        That's it!{" "}
        <a
          href="/?v=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DaJWAwmaUsUQ&t=Basic%20Gospel%20Progressions%20(Worship%20Leader%20Hangout)&b=736m514%20(Db),02:20,02:30&b=736m514%20(D),04:04,04:17&b=736m514%20(E),04:23,04:37&b=736m514%20(F),04:50,05:11&b=Breakdown%20(Db),05:29,06:16&b=736m514%20(C),06:59,07:09&b=Awesome%20Solo,08:29,00:00"
          target="_blank"
        >
          Click here for a sample loop I made to learn some basic piano gospel
          progressions
        </a>{" "}
        from{" "}
        <a
          href="https://www.youtube.com/channel/UCtcmgokNJGuLVwZ0Tw92Szw"
          target="_blank"
        >
          Worship Leader Hangout.
        </a>
      </Typography>
      <Typography variant="h6" component="div" gutterBottom mt={6}>
        Happy looping!!
      </Typography>
      <Copyright />
    </Box>
  );
}
