import { Routes, Route, Link } from "react-router-dom";
import LoopAndLearn from "./LoopAndLearn";
import Instructions from "./Instructions";
import Blog from "./Blog";
import Catalog from "./Catalog";
import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./styles.css";
import ReactGA from "react-ga4";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function App() {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  if (!window.location.href.includes("localhost")) {
    ReactGA.initialize("G-XV1VPB00GR");
  }

  useEffect(() => {
    const setWidthOnResize = () => {
      const computedStyle = getComputedStyle(
        document.getElementById(containerRef.current.id)
      );
      const paddingLeft = parseFloat(computedStyle.paddingLeft);
      const paddingRight = parseFloat(computedStyle.paddingRight);
      if (containerRef.current.getBoundingClientRect())
        setContainerWidth(
          containerRef.current.clientWidth - paddingLeft - paddingRight
        );
    };
    setWidthOnResize();
    window.addEventListener("resize", setWidthOnResize);
    return () => {
      window.removeEventListener("resize", setWidthOnResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        ref={containerRef}
        maxWidth="md"
        id="main-content"
        sx={{ my: 8 }}
      >
        <Typography variant="h6" fontSize="1.08rem">
          <nav style={{ float: "right" }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
              }}
            >
              App
            </Link>
            <Link
              to="/catalog"
              className="navStyle"
              style={{
                paddingLeft: containerWidth < 400 ? 15 : 30,
                textDecoration: "none",
              }}
            >
              Examples
            </Link>
            <Link
              to="/blog"
              className="navStyle"
              style={{
                paddingLeft: containerWidth < 400 ? 15 : 30,
                textDecoration: "none",
              }}
            >
              Blog
            </Link>
            <Link
              to="/howto"
              style={{
                paddingLeft: containerWidth < 400 ? 15 : 30,
                textDecoration: "none",
              }}
            >
              Help
            </Link>
          </nav>
        </Typography>
        <div style={{ clear: "both" }} />
        <Routes>
          <Route
            path="/"
            element={<LoopAndLearn containerWidth={containerWidth} />}
          />
          <Route
            path="howto"
            element={<Instructions containerWidth={containerWidth} />}
          />
          <Route
            path="blog"
            element={<Blog containerWidth={containerWidth} type={"main"} />}
          />
          <Route
            path="catalog"
            element={<Catalog containerWidth={containerWidth} />}
          />
          <Route
            path="s/*"
            element={<LoopAndLearn containerWidth={containerWidth} />}
          />
          <Route
            path="blog/page/*"
            element={<Blog containerWidth={containerWidth} type={"page"} />}
          />
          <Route
            path="entry/*"
            element={<Blog containerWidth={containerWidth} type={"entry"} />}
          />
          <Route
            path="tag/*"
            element={<Blog containerWidth={containerWidth} type={"tag"} />}
          />
        </Routes>
      </Container>
    </ThemeProvider>
  );
}
