import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  validateTimeFormat,
  hmsToSecondsOnly,
  secondsToHHMMSS,
} from "../common/time";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import { trackEvent } from "../common/analytics";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function LoopEditor(props) {
  const [startInput, setStartInput] = useState("0:00");
  const [startInputError, setStartInputError] = useState(false);
  const [startInputHelperText, setStartInputHelperText] = useState("");

  const [endInput, setEndInput] = useState("");
  const [endInputError, setEndInputError] = useState(false);
  const [endInputHelperText, setEndInputHelperText] = useState("");

  const [bookmarkName, setBookmarkName] = useState("");
  const [bookmarkNameError, setBookmarkNameError] = useState(false);
  const [bookmarkNameHelperText, setBookmarkNameHelperText] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [liveMode, setLiveMode] = useState(true);

  useEffect(() => {
    if (props.bookmarkToEdit != null && !editMode) {
      setEditMode(true);
      setStartInput(props.bookmarkToEdit.start);
      setStartInputError(false);
      setStartInputHelperText("");
      setEndInput(props.bookmarkToEdit.end);
      setEndInputError(false);
      setEndInputHelperText("");
      setBookmarkName(props.bookmarkToEdit.name);
      setBookmarkNameError(false);
      setBookmarkNameHelperText("");
    }
  });

  function validateBookmarkName() {
    let status = true;
    if (!bookmarkName) {
      setBookmarkNameError(true);
      setBookmarkNameHelperText("Name cannot be empty");
      status = false;
    } else if (
      props.bookmarks.map((mark) => mark.name).includes(bookmarkName)
    ) {
      setBookmarkNameError(true);
      setBookmarkNameHelperText("Name must be unique");
      status = false;
    } else if (bookmarkName.length > 30) {
      setBookmarkNameError(true);
      setBookmarkNameHelperText("Name cannot exceed 30 chars");
      status = false;
    }
    return status;
  }

  function validateSingleTime(time, setInputError, setInputHelperText) {
    let status = true;
    if (!validateTimeFormat(time)) {
      setInputError(true);
      setInputHelperText("Specify in hh:mm:ss");
      status = false;
    } else if (
      props.mediaDuration > 0 &&
      hmsToSecondsOnly(time) > props.mediaDuration
    ) {
      setInputError(true);
      setInputHelperText("Out of range");
      status = false;
    } else {
      setInputError(false);
      setInputHelperText("");
    }
    return status;
  }

  function validateTimes(start, end) {
    let status = true;
    status = validateSingleTime(
      start,
      setStartInputError,
      setStartInputHelperText
    );
    status =
      end == "" ||
      validateSingleTime(end, setEndInputError, setEndInputHelperText);

    if (
      hmsToSecondsOnly(start) > hmsToSecondsOnly(end) &&
      hmsToSecondsOnly(end) > 0
    ) {
      setStartInputError(true);
      setStartInputHelperText("Start cannot be after end!");
      status = false;
    }

    return status;
  }

  function onCancel() {
    // This is how we know that we are in new/edit mode
    setBookmarkName("");
    setStartInput("");
    setEndInput("");
    setBookmarkNameHelperText("");
    setBookmarkNameError(false);
    setStartInputHelperText("");
    setStartInputError(false);
    setEndInputHelperText("");
    setEndInputError(false);
    if (editMode) {
      setEditMode(false);
      props.setBookmarkToEdit(null);
    }
  }

  function addBookmark() {
    if (!editMode) {
      if (!(validateBookmarkName() && validateTimes(startInput, endInput)))
        return;
    }

    let startInputCleaned = startInput;
    let endInputCleaned = endInput;
    if (startInput >= 0 && startInput < 100) {
      startInputCleaned = "0:" + startInput;
    }
    if (endInput >= 0 && endInput < 100 && endInput != "") {
      endInputCleaned = "0:" + endInput;
    }

    // All validations passed
    setBookmarkNameHelperText("");
    setBookmarkNameError(false);
    setStartInputHelperText("");
    setStartInputError(false);
    setEndInputHelperText("");
    setEndInputError(false);

    const newBookmark = {
      name: bookmarkName,
      start: startInputCleaned,
      end: endInputCleaned,
    };
    let newBookmarks = [];
    if (editMode) {
      newBookmarks = [...props.bookmarks];
      const index = props.bookmarks.findIndex(
        (mark) => mark.name == props.bookmarkToEdit.name
      );
      newBookmarks[index] = newBookmark;
      props.setBookmarkToEdit(null);
    } else {
      newBookmarks = [...props.bookmarks, newBookmark];
    }
    props.updateBookmarks(newBookmarks);
    trackEvent(
      "LoopEditor",
      "Confirm",
      (editMode ? "Edit: " + props.bookmarkToEdit.name + " -> " : "Create: ") +
        JSON.stringify(newBookmark)
    );

    onCancel();
    if (!editMode) setStartInput(endInputCleaned);
  }

  let title = "Add New Loop";
  let addButtonText = "Create";
  let cancelButtonText = "Clear";
  let backgroundColor = "#DDDDee";
  if (editMode) {
    title = "Edit Loop";
    addButtonText = "Confirm Edit";
    cancelButtonText = "Cancel";
    backgroundColor = "#eeeedd";
  }

  return (
    <Card
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
        marginBottom: 1,
        backgroundColor: { backgroundColor },
      }}
    >
      <CardContent sx={{ height: props.expanded ? null : 12 }}>
        <CardActions disableSpacing sx={{ height: props.expanded ? null : 12 }}>
          <Typography
            sx={{ fontSize: props.expanded ? 20 : 15, marginBottom: 0 }}
            onClick={(e) => props.setExpanded(!props.expanded)}
          >
            {title}
          </Typography>
          <ExpandMore
            expand={props.expanded}
            onClick={(e) => props.setExpanded(!props.expanded)}
            aria-expanded={props.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={props.expanded} timeout="auto" unmountOnExit>
          <Box>
            <Button
              variant="outlined"
              onClick={(e) => {
                const startValue = secondsToHHMMSS(
                  Math.max(0, props.getCurrentTimeCallback() - 0.5)
                );
                setStartInput(startValue);
                trackEvent("LoopEditor", "SetStart", "Button");
              }}
            >
              Set Start
            </Button>
            <Button
              sx={{ marginLeft: 2 }}
              variant="outlined"
              onClick={(e) => {
                const endValue = secondsToHHMMSS(
                  Math.max(0, props.getCurrentTimeCallback() - 0.5)
                );
                setEndInput(endValue);
                trackEvent("LoopEditor", "SetEnd", "Button");
              }}
            >
              Set End
            </Button>
          </Box>

          <Box sx={{ my: 2 }}>
            <TextField
              sx={{ marginRight: 4, maxWidth: 92 }}
              value={startInput}
              error={startInputError}
              helperText={startInputHelperText}
              id="start"
              label="Start"
              shrink="true"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              placeholder="0:00"
              onChange={(e) => {
                if (!isNaN(e.target.value.replaceAll(":", ""))) {
                  setStartInput(e.target.value);
                  if (
                    liveMode &&
                    validateSingleTime(
                      e.target.value,
                      setStartInputError,
                      setStartInputHelperText
                    )
                  ) {
                    props.setLiveUpdate(e.target.value);
                  }
                }
              }}
            />
            <TextField
              sx={{ marginRight: 4, maxWidth: 92 }}
              value={endInput}
              error={endInputError}
              helperText={endInputHelperText}
              id="end"
              label="End (Optional)"
              variant="standard"
              shrink="true"
              InputLabelProps={{ shrink: true }}
              placeholder="None"
              onChange={(e) => {
                if (
                  e.target.value == "" ||
                  !isNaN(e.target.value.replaceAll(":", ""))
                ) {
                  setEndInput(e.target.value);

                  if (
                    e.target.value != "" &&
                    liveMode &&
                    validateSingleTime(
                      e.target.value,
                      setEndInputError,
                      setEndInputHelperText
                    )
                  ) {
                    props.setLiveUpdate(e.target.value);
                  }
                }
              }}
            />
            <TextField
              sx={{ marginRight: 2 }}
              value={bookmarkName}
              error={bookmarkNameError}
              helperText={bookmarkNameHelperText}
              default=""
              id="bookmark-name"
              label="Loop Name"
              variant="standard"
              onChange={(e) => {
                setBookmarkName(e.target.value);
              }}
            />
            <Button sx={{ my: 1 }} variant="contained" onClick={addBookmark}>
              {addButtonText}
            </Button>
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={liveMode}
                onChange={(e) => {
                  setLiveMode(e.target.checked);
                  trackEvent("LoopEditor", "ToggleLive", e.target.checked);
                }}
              />
            }
            label={liveMode ? "Live Update ON" : "Live Update OFF"}
          />
          <Button
            sx={{ marginLeft: 2 }}
            color="error"
            variant="outlined"
            onClick={(e) => {
              onCancel();
              trackEvent("LoopEditor", "Cancel", "Button");
            }}
          >
            {cancelButtonText}
          </Button>
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default LoopEditor;
