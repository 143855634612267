import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { trackEvent } from "../common/analytics";

function Bookmarks(props) {
  if (props.bookmarks.length == 0)
    return (
      <Typography sx={{ marginTop: 4 }}>
        Hmmm... I don't see any bookmarks yet. Add some!
      </Typography>
    );

  const [dragAndDrop, setDragAndDrop] = useState({
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [...props.bookmarks],
    updatedOrder: [],
  });

  const onDragStart = (e) => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: Number(e.currentTarget.id),
      isDragging: true,
      originalOrder: [...props.bookmarks],
    });
  };

  const onDragOver = (e) => {
    //This makes it so ondropped can get called
    e.preventDefault();
  };

  const onDropped = (e) => {
    // Prevents "open" on drop as is default behavior
    e.preventDefault();
    const origin = dragAndDrop.draggedFrom;
    const destination = Number(e.currentTarget.id);
    if (dragAndDrop.isDragging && origin != destination) {
      const domRect = e.currentTarget.getBoundingClientRect();
      const dropYOnObject = e.nativeEvent.offsetY;
      const objectHeight = domRect.height;

      const offset = dropYOnObject < objectHeight / 2 ? 0 : 1;
      const updatedBookmarks = [...dragAndDrop.originalOrder];
      updatedBookmarks.splice(
        destination + offset,
        0,
        dragAndDrop.originalOrder[origin]
      );

      let toRemove = origin < destination ? origin : origin + 1;

      updatedBookmarks.splice(toRemove, 1);

      setDragAndDrop({
        ...dragAndDrop,
        draggedTo: Number(e.currentTarget.id),
        isDragging: false,
        updatedOrder: updatedBookmarks,
      });

      props.onReorderCallback(e, updatedBookmarks);
    }
  };

  const card = props.bookmarks.map((bookmark, i) => {
    const StartEndText = () => {
      return props.screenWidth > 400 ? (
        <Typography>
          Start: {bookmark.start} End: {bookmark.end ? bookmark.end : "-- : --"}
        </Typography>
      ) : (
        <Box>
          <Typography sx={{ fontSize: 12 }}>{bookmark.start} -</Typography>
          <Typography sx={{ fontSize: 12 }}>{bookmark.end}</Typography>
        </Box>
      );
    };

    return (
      <Accordion key={i}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor:
              bookmark.name == props.highlighted ? "yellow" : "#F3F3F3",
          }}
          aria-controls="panel1b-header"
          id="panel1b-header"
          draggable="true"
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDrop={onDropped}
          id={i}
        >
          <Typography
            noWrap
            sx={{
              width: "60%",
              fontSize: props.screenWidth > 400 ? "1rem" : ".8rem",
              flexShrink: 0,
              fontWeight:
                bookmark.name == props.highlighted ? "bold" : "normal",
            }}
          >
            {bookmark.name}
          </Typography>
          <Box
            sx={{
              width: "25%",
              color: "text.secondary",
              fontWeight:
                bookmark.name == props.highlighted ? "bold" : "normal",
            }}
          >
            <StartEndText />
          </Box>
          <Typography sx={{ marginRight: ".3rem" }}>
            <Button
              variant="outlined"
              onClick={(e) => {
                props.onClickCallback(e, bookmark);
                trackEvent("Bookmarks", "Load", "Button");
              }}
            >
              Load
            </Button>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="center">
            <Button
              size="small"
              sx={{ mx: 8 }}
              variant="outlined"
              color="warning"
              onClick={(e) => {
                props.onEditCallback(e, bookmark);
                trackEvent("Bookmarks", "Edit", "Button");
              }}
            >
              Edit
            </Button>
            <Button
              size="small"
              sx={{ mx: 8 }}
              variant="outlined"
              color="error"
              onClick={(e) => {
                props.onDeleteCallback(e, bookmark);
                trackEvent("Bookmarks", "Delete", "Button");
              }}
            >
              Delete
            </Button>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  });
  return <Box>{card}</Box>;
}

export default Bookmarks;
