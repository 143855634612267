import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "./components/Copyright";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { Helmet } from "react-helmet";

const query = `
query blogPostCollection($limit:Int, $skip: Int) {
    blogPostCollection(order: [category_ASC, suzukiIndex_ASC, title_ASC], limit: $limit, skip: $skip) {
      items {
        title
        category
        suzukiIndex
        slug
      }
    }
  }
  `;

const SuzukiBook = (props) => {
  return (
    <Box>
      <Typography variant="h3" ml="1rem" fontSize="1.1rem !important" mb="1rem">
        Book {props.book}
      </Typography>
      {props.posts.map((post, index) => (
        <Link
          href={"entry/" + post.slug}
          key={props.category + "_" + post.suzukiIndex + "_" + index}
        >
          <Typography
            mb={"1em"}
            variant="h4"
            fontSize="1.0rem !important"
            ml="2rem"
          >
            {post.suzukiIndex} {post.title}
          </Typography>
        </Link>
      ))}
    </Box>
  );
};

const SuzukiList = (props) => {
  const postsInCategory = props.postsJson.filter(
    (post) => post.category == props.category
  );

  const books = [
    ...new Set(
      postsInCategory
        .map((post) => Math.floor(parseFloat(post.suzukiIndex)))
        .filter((x) => !isNaN(x))
    ),
  ].sort();

  return books.length > 0 ? (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="h2" mb="1rem" fontSize="1.5rem !important">
        {props.category}
      </Typography>
      {books.map((book) => (
        <SuzukiBook
          book={book}
          key={props.category + "Book" + book}
          posts={postsInCategory.filter(
            (post) => Math.floor(parseFloat(post.suzukiIndex)) == book
          )}
        />
      ))}
    </Grid>
  ) : null;
};

const NonSuzukiList = (props) => {
  const postsInCategory = props.postsJson.filter(
    (post) => post.category == props.category
  );
  return postsInCategory.length > 0 ? (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="h2" mb="1rem" fontSize="1.5rem !important">
        {props.category}
      </Typography>
      {postsInCategory.map((post, index) => (
        <Link href={"entry/" + post.slug} key={props.category + "_" + index}>
          <Typography
            mb={"1em"}
            variant="h4"
            fontSize="1.0rem !important"
            ml="2rem"
          >
            {post.categoryIndex} {post.title}
          </Typography>
        </Link>
      ))}
    </Grid>
  ) : null;
};

export default function Catalog(props) {
  const [postsJson, setPostsJson] = useState([]);

  useEffect(() => {
    const variables = {};
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/901u2fmlln4b/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer yBPrZ3midgXECttXOe8mrHUpIcYOz7pktEotZuc32Uw",
        },
        // send the GraphQL query
        body: JSON.stringify({ query, variables }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setPostsJson(data.blogPostCollection.items);
      });
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Loop Catalog</title>
        <meta
          name="description"
          content="A curated collection of our favorite microlearning loops! Loopandlearn.app allows musicians to create, save, bookmark, and share multiple sections of Youtube and other streaming video/audio sources to study and play along with."
        />
      </Helmet>
      <Box sx={{ marginBottom: "3rem" }}>
        <Typography variant="h1" component="div" gutterBottom>
          Loop Catalog
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          I made some example loops to get you started!
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <SuzukiList category={"Suzuki Violin"} postsJson={postsJson} />
        <SuzukiList category={"Suzuki Viola"} postsJson={postsJson} />
        <SuzukiList category={"Suzuki Cello"} postsJson={postsJson} />
        <NonSuzukiList category={"Violin"} postsJson={postsJson} />
        <NonSuzukiList category={"Cello"} postsJson={postsJson} />
        <NonSuzukiList category={"Piano"} postsJson={postsJson} />
        <NonSuzukiList category={"Vocal"} postsJson={postsJson} />
        <NonSuzukiList category={"Guitar"} postsJson={postsJson} />
        <NonSuzukiList category={"Bass Guitar"} postsJson={postsJson} />
        <NonSuzukiList category={"Drums"} postsJson={postsJson} />
        <NonSuzukiList category={"Other"} postsJson={postsJson} />
      </Grid>

      <Copyright />
    </Box>
  );
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
